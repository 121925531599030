import React, { useState, useEffect, useRef } from 'react';
import './BottomSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import database from '../firebase';
import { ref, onValue, off } from 'firebase/database';
import ReactCardFlip from 'react-card-flip';

const l = ['A', 'B', 'C', 'D'];

function BottomSection({ mode, contest, channelId, gameSound }) {
  const [players, setPlayers] = useState([]);
  const prevPlayersRef = useRef([]);

  useEffect(() => {
    const roomId = channelId || "_main";
    const playersRef = ref(database, `rooms/${roomId}/players`);

    const handleData = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const playersList = Object.values(data).filter(player => !player.inactive);
        
        if (prevPlayersRef.current.length > 0) {
          playersList.forEach(player => {
            const prevPlayer = prevPlayersRef.current.find(p => p.userid === player.userid);
            if (prevPlayer && 
                typeof player.optionIndexSelected === 'number' && 
                typeof prevPlayer.optionIndexSelected !== 'number') {
              if (gameSound) {
                gameSound.answer.play();
              }
            }
          });
        }
        
        prevPlayersRef.current = playersList;
        setPlayers(playersList);
      } else {
        prevPlayersRef.current = [];
        setPlayers([]);
      }
    };

    onValue(playersRef, handleData, (error) => {
      console.error("Error fetching players:", error);
    });

    return () => {
      off(playersRef, 'value', handleData);
    };
  }, [channelId, gameSound]);

  return (
    <>
      {mode !== 0 && (
        <div className="bottom-section" style={{ flexDirection: 'row' }}>
          <div className="center">
            <div className="avatar-container">
              {players.map((player) => {
                const avatarStyle = player.avatar ? {
                  backgroundImage: `url(${player.avatar})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                } : {};

                const hasAnswered = typeof player.optionIndexSelected === 'number';
                const isCorrect = player.status === "correct";
                const initials = player.name.slice(0, 2).toUpperCase();
                const reveal = (mode === 3 || mode === 4 || mode === 5);
                const noAvatar = !!player.avatar;

                let borderColor = 'rgba(255,255,255,0)';
                if (hasAnswered) {
                  borderColor = 'yellow';
                  if (reveal) {
                    if (isCorrect) {
                      borderColor = '#00E096';
                    } else {
                      borderColor = 'red';
                    }
                  }
                }
                avatarStyle.borderColor = borderColor;

                const isNewPlayer = !prevPlayersRef.current.some(p => p.userid === player.userid);
                const isLeavingPlayer = !players.some(p => p.userid === player.userid);

                return (
                  <div 
                    key={player.userid} 
                    className={`avatar-wrapper ${isNewPlayer ? 'new-player' : ''} ${isLeavingPlayer ? 'leaving-player' : ''}`}
                  >
                    <div 
                      className={`unanswered-avatar ${hasAnswered && !reveal ? 'rotating-border' : ''}`} 
                      style={avatarStyle}
                    >
                      <span className="avatar-initials">{!noAvatar ? initials : ''}</span>
                      <span className="streak-badge">{player.emoji ? player.emoji : ''}</span>
                      {(mode === 3 || mode === 4) && (
                        <span 
                          className="a-badge" 
                          style={{ backgroundColor: borderColor }}
                        >
                          {hasAnswered ? l[player.optionIndexSelected] : ''}
                        </span>
                      )}
                    </div>
                    <span className="avatar-name">{player.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BottomSection;