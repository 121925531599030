import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from 'react-router-dom';
import { Howl } from 'howler';
import { Volume2, VolumeX } from 'lucide-react';
import database from './firebase';
import { getDatabase, ref, onValue, set } from 'firebase/database';
import TopSection from './components/TopSection';
import BottomSection from './components/BottomSection';
import SwybleIntroComponent from './components/SwybleIntroComponent';
import QuestionComponent from './components/QuestionComponent';
import LeaderBoardComponent from './components/LeaderBoardComponent';
import ComingNextComponent from './components/ComingNextComponent';
import BackgroundMusic from './components/BackgroundMusic';
import './App.css';
import LeaderboardList from './components/LeaderBoard';
import QRComponent from './components/QRComponent';
import ProgressBar from './components/ProgressBar';

// Initialize sound objects
const sounds = {
  answer: new Howl({
    src: ['mp3/answer.mp3'],
    preload: true,
    volume: 0.3
  }),
  background: new Howl({
    src: ['mp3/background.mp3'],
    preload: true,
    volume: 0.1
  }),
  timeup: new Howl({
    src: ['mp3/timeup.mp3'],
    preload: true,
    volume: 0.1
  }),
  intro: new Howl({
    src: ['mp3/intro.mp3'],
    preload: true,
    volume: 0.1
  }),
  cover: new Howl({
    src: ['mp3/cover.mp3'],
    preload: true,
    volume: 0.1
  }),
  reveal: new Howl({
    src: ['mp3/reveal.mp3'],
    preload: true,
    volume: 0.1
  }),
  leaderboard: new Howl({
    src: ['mp3/leaderboard.mp3'],
    preload: true,
    volume: 0.1
  })
};

// Sound Toggle Component
const SoundToggle = ({ isSoundOn, toggleSound }) => {
  return (
    <button
      onClick={toggleSound}
      className="fixed"
      style={{top:100,right:0,width:'auto',position:'absolute',zIndex:20}}
      aria-label={isSoundOn ? "Mute sounds" : "Unmute sounds"}
    >
      {isSoundOn ? (
        <Volume2 className="w-8 h-6 text-white" />
      ) : (
        <VolumeX className="w-8 h-6 text-white" />
      )}
    </button>
  );
};

function ChannelComponent() {
  const { channelId = '_main' } = useParams();
  const [pageState, setPageState] = useState(null);
  const [leaders, setLeaders] = useState([]);
  const [isBlurred, setIsBlurred] = useState(true);
  const [duration, setDuration] = useState(0);
  const [blur, setBlur] = useState(24);
  const [isSoundOn, setIsSoundOn] = useState(false);
  
  const toggleSound = () => {
    setIsSoundOn(!isSoundOn);
    Object.values(sounds).forEach(sound => {
      if (!isSoundOn) {
        sound.mute(false);
      } else {
        sound.mute(true);
      }
    });
  };

  const appStyle = {
    backgroundImage: pageState?.Question?.topic?.poster
      ? `url(${pageState.Question.topic.poster})`
      : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    transition: 'backdrop-filter 0.3s ease',
  };

  // Sound management based on mode changes
  useEffect(() => {
    if (!pageState || !isSoundOn) return;

    // Stop all sounds first
    Object.values(sounds).forEach(sound => sound.stop());

    switch (pageState.mode) {
      case 0: // QR Mode
        sounds.intro.play();
        break;
      case 1: // Intro Mode
        sounds.cover.play();
        break;
      case 3: // Answer Mode
        sounds.timeup.play();
        break;
      case 2: // Question Mode
        sounds.background.play();
        break;
        case 5: // Question Mode
        sounds.leaderboard.play();
        break;
    }
    
    // Cleanup function
    return () => {
      Object.values(sounds).forEach(sound => sound.stop());
    };
  }, [pageState?.mode, isSoundOn]);

  useEffect(() => {
    const db = getDatabase();
    const pageStateRef = ref(db, `rooms/${channelId}`);
    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      if (data && data.players) {
        const playerList = Object.values(data.players)
          .sort((a, b) => (b.score || 0) - (a.score || 0))
          .map((player, index) => ({
            rank: index + 1,
            username: player.name || 'anon',
            score: player.score || 0,
            status: player.status ||'',
            streak:player.streak||0,
            avatar: player.avatar ||'',
            coins: player.coins || 0,
          }));
        setLeaders(playerList);
      }
    };
    onValue(pageStateRef, onDataChange, (error) => {
      console.error('Firebase data fetch error:', error);
    });
    const unsubscribe = onValue(pageStateRef, (snapshot) => {
      setPageState(snapshot.val());
      setIsBlurred(pageStateRef.mode === 2 || pageStateRef.mode === 3);
    });

    return () => unsubscribe();
  }, [channelId]);

  useEffect(() => {
    switch (pageState?.mode) {
      case 0:
        setDuration(10);
        setBlur(0);
        break;
      case 1:
        setDuration(5);
        setBlur(12);
        break;
      case 2:
        setDuration(15);
        setBlur(24);
        break;
      case 3:
        setDuration(5);
        break;
      case 4:
        setBlur(12);
        break;
      case 5:
        setBlur(6);
        break;
      case 6:
        setDuration(10);
        break;
      default:
        setDuration(10);
    }
  }, [pageState]);

  if (!pageState) return <div>Loading...</div>;

  const renderComponent = () => {
    switch (pageState.mode) {
      case 0:
        return <QRComponent channelId={channelId} />;
      case 1:
        return <SwybleIntroComponent question={pageState.Question} />;
      case 2:
      case 3:
      case 4:
        return (
          <QuestionComponent
            question={pageState.Question}
            mode={pageState.mode}
          />
        );
      case 5:
        return <LeaderboardList players={leaders} />;
      default:
        return null;
    }
  };

  return (
    <div className='App' style={appStyle}>
      <SoundToggle isSoundOn={isSoundOn} toggleSound={toggleSound} />
      {(pageState.mode!==0 &&pageState.mode!==1)&&<TopSection
        mode={pageState.mode}
        question={pageState.Question}
        channelId={channelId}
      />}
      <div className='main-content' style={{backdropFilter:`blur(${blur}px)`, transition: 'backdrop-filter 0.5s ease'}}>
        {renderComponent()}
      </div>
      <BottomSection
        mode={pageState.mode}
        contest={pageState.Contest}
        channelId={channelId}
        gameSound={sounds}
      />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/channel/:channelId' element={<ChannelComponent />} />
        <Route path='/' element={<ChannelComponent />} />
      </Routes>
    </Router>
  );
}

export default App;