
// src/components/TopSection.js

import React from 'react';
import './TopSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faCheckCircle, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from './ProgressBar';

function TopSection({ mode, question,channelId }) {
  return (
    <div className="topbar">
      <div className="topbar-section  h2">
{/*       <img src="swyble.png" className="logo" style={{width:150}}/> */}
      </div>
{<div className="topbar-section">
        {(mode==2)&&<ProgressBar d={15}/>}

      </div>}
      <div className="topbar-section right">

      </div>
    </div>
  );
}

function Icon({ type, active }) {
  return (
    <div className={`icon ${active ? 'active' : ''}`}>
      <FontAwesomeIcon icon={type} />
    </div>
  );
}

export default TopSection;