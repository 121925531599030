import React, { useMemo } from 'react';
import { Trophy } from 'lucide-react';
import MiniWallet from './MiniWallet';
import './LeaderBoard.css';
import confetti from 'canvas-confetti';

const LeaderboardList = ({ players }) => {
  const sortedPlayers = useMemo(() => {
    return [...players].sort((a, b) => b.coins - a.coins)
      .map((player, index) => ({ ...player, rank: index + 1 }));
  }, [players]);

  // Run confetti only on first render
  React.useEffect(() => {
    confetti({
      particleCount: 15,
      spread: 100,
      useWorker: true,
      colors: ['FFE400','00E096']
    });
  }, []);

  const LeaderboardItem = React.memo(({ entry, index }) => (
    <div
      className='leaderboard-item'
      style={{
        animation: `fadeIn 200ms ${index * 100}ms forwards`,
      }}
    >
      <div
        className='rank-container'
        style={{
          animation: `scaleIn 500ms ${index * 100 + 200}ms forwards`,
        }}
      >
        <span className='rank-text'>{entry.rank}</span>
      </div>

      <div className='user-info'>
        <div
          className='avatar-container'
          style={{
            animation: `scaleIn 500ms ${index * 100 + 300}ms forwards`,
          }}
        >
          <div className='avatar'>
            {entry.avatar ? (
              <img
                src={entry.avatar}
                alt={`${entry.username}'s avatar`}
                className='avatar-image'
              />
            ) : (
              <span>{entry.username.slice(0, 2).toUpperCase()}</span>
            )}
          </div>
        </div>

        <span
          className='username'
          style={{
            animation: `slideIn 500ms ${index * 100 + 400}ms forwards`,
          }}
        >
          {entry.username}
        </span>
      </div>

      <div className='score'>
        <MiniWallet size={15} balance={entry.coins} />
      </div>
    </div>
  ));

  const midpoint = Math.ceil(sortedPlayers.length / 2);
  const leftColumnPlayers = sortedPlayers.slice(0, midpoint);
  const rightColumnPlayers = sortedPlayers.slice(midpoint);

  return (
    <div>
      <div className='leaderboard-container'>
        <div style={{ overflow:'hidden'}}>
          {leftColumnPlayers.map((player, index) => (
            <LeaderboardItem key={player.rank} entry={player} index={index} />
          ))}
        </div>
        <div style={{ overflow:'hidden'}}>
          {rightColumnPlayers.map((player, index) => (
            <LeaderboardItem
              key={player.rank}
              entry={player}
              index={index + midpoint}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(LeaderboardList);