import React from 'react';
import { createRoot } from 'react-dom/client';
import QRCode from 'react-qr-code';
import './SwybleIntroComponent.css';

// Root rendering setup
const container = document.getElementById('Container');
if (container) {
  const root = createRoot(container);
  root.render(<QRCode />);
}

function QRComponent(channelId) {
  const channelID = JSON.stringify(channelId?.channelId).replace(/"/g, '');

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  console.log('current: ' + channelID);
  return (
    <div className='qr'>
      <div className="column bling">
        <img src="swyble.png" className="logo" />

      </div>
      <div className="column"><h3 className="subtitle">Scan Code</h3>
        <QRCode
          size={window.innerWidth / 4}
          bgColor='#ffffff'
          value={`https://tv.swyble.com/channel/${channelID}`}
          viewBox='0 0 256 256'
        />
        <h3 className="body-text">Enter Code in App</h3>
        <h1 className="h1 textbox" style={{color:"#ffdd55"}}>{channelID}</h1>
      </div>


    </div>
  );
}

export default QRComponent;
